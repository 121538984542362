// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    base_url: 'https://unifiedfrost-acmt-qa.learningmate.co/server/',
    version: 'api/v1/',
    auth_url: '',
    s3_bucket: 'acmthost',
    faq_url: 'https://unifiedfrost-acmt-qa.learningmate.co/faq/',
    recaptcha_site_key: '6LdshK8ZAAAAAOeLl4Bjzyr557-Ws9vBwHtKM_Al',
    showPublishedTaxonomy: true,
    renamePacingGuide: false,
    tenant: [''],
    env: 'admin-qa',
    allowTenantSpecificChangesOnly: [''],
    domain: 'unifiedfrost',
    unifiedLogin: 'https://unifiedfrost-auth-qa.learningmate.co/#/login',
    unifiedLogout: 'https://unifiedfrost-auth-qa.learningmate.co/#/logout',
    unifiedAdmin: 'https://unifiedfrost-admin-qa.learningmate.co/',
    unifiedForgotPassword: 'https://unifiedfrost-auth-qa.learningmate.co/#/forget-password',
    SSO_USER: true,
    prTenants: ['nik01', 'tst32'],
    adminHost: 'https://unifiedfrost-admin-qa.learningmate.co/',
    apiHostAuthServer: 'https://unifiedfrost-auth-api-qa.learningmate.co/oauth/',
    client_id: 'ffb01b87-af95-4645-bea0-da801b0a2869',
    client_secret: 'gninraeletam',
    grant_type: 'refresh_token',
    env_file: 'mtqa'
};
